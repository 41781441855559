export const isTokenValid = () => {
  const tokenExpiry = sessionStorage.getItem('tokenExpiry')
  const token = sessionStorage.getItem('token')
  const date = Date.now()
  if (!token || !tokenExpiry || tokenExpiry < date) {
    sessionStorage.removeItem('tokenExpiry')
    sessionStorage.removeItem('token')
    return false
  } else {
    return true
  }
}

export const addSessionTime = () => {
  const tokenExpiry = Date.now() + 60000
  sessionStorage.setItem('tokenExpiry', tokenExpiry)
}

export const addSessionToken = (token) => {
  if (token) {
    sessionStorage.setItem('token', token)
    addSessionTime()
  }
}
