import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { addSessionTime, isTokenValid } from 'src/util/auth'
import { SESSION_TIME } from 'src/util/constants'

const IdleSessionTimeout = ({ children }) => {
  const navigate = useNavigate()
  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll']
    events.forEach((event) => {
      document.addEventListener(event, addSessionTime)
    })

    const intervalId = setInterval(() => {
      // if (!isTokenValid()) navigate('/')
      if (!isTokenValid()) {
        alert('Session Timout! Please login again')
        navigate('/')
      }
    }, SESSION_TIME + 1000)

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, addSessionTime)
      })
      clearInterval(intervalId)
    }
  }, [navigate])
  return <>{children}</>
}

IdleSessionTimeout.propTypes = {
  children: PropTypes.node.isRequired, // Ensure 'children' is passed and is of type 'node'
}
export default IdleSessionTimeout
