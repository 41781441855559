/* eslint-disable prettier/prettier */
import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import IdleSessionTimeout from './components/IdleSessionTimeout'
import { isTokenValid } from './util/auth'

const ProtectedRoute = ({ children }) => {
  const isLoggedIn = isTokenValid()

  return isLoggedIn ? <IdleSessionTimeout>{children}</IdleSessionTimeout> : <Navigate to="/" />
}

// Prop type validation
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired, // Ensure 'children' is passed and is of type 'node'
}

export default ProtectedRoute
