/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    // Perform logout logic
    sessionStorage.removeItem('token') // or sessionStorage.clear() if using session
    navigate('/login') // Redirect to the login page after logout
  }, [navigate])

  return <div>Logging out...</div>
}

export default Logout
